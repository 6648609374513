.project-detail-photos{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    justify-content: center;
}
.project-detail-photos img{
    width: 100%;
    border:1px solid gray;
   
}
.features{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin:5px 0px;
}