.contact {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.section-title {
  font-size: 32px;
  font-weight: 700;
}
.contact-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px;
}
.contact-left {
  max-width: 60%;
  font-size: 20px;
  text-align: center;
}

.download {
  background-color: aquamarine;
  padding: 8px 16px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.download a {
  text-decoration: none;
  color: black;
}
.download-icon {
}

@media only screen and (max-width: 720px) {
  .contact-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-left {
    max-width: 100%;
  }
  .contact-left {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .download {
    font-size: 16px;
  }
}

form input,textarea {
  width: 75%;
  padding: 12px 10px;
  margin:5px;
  border:2px solid gray;
  border-radius: 15px;
  
}
.submitBtn{
  background-color: green;
  border:0px solid white;
  color:white;
  font-size: 1em;
  cursor: pointer;
}