.about {
  /* height: 75vh; */
  padding-bottom: 48px;
}
.about-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.picture {
  height: 420px;
}
.about-info {
  font-size: 24px;
}
.info-name {
  font-size: 32px;
  color: blue;
}
.resumeBtn{
  border: 0px;
  border-radius: 25px;
  padding: 15x 15px;
  display: block;
  margin: 10px 0px;
  font-size: 23px;
  box-shadow: 10px 10px 10px #5e5e5ea6;
  background-color: rgb(102 14 243);
  cursor: pointer;
  padding: 10px 29px;
  color: white;
  font-weight: 100;
}
@media only screen and (max-width: 720px) {
  .about-top {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .picture {
    height: 280px;
  }
  .about-info {
    font-size: 20px;
  }
  .info-name {
    font-size: 26px;
  }
}
