body {
  font-family: "Work Sans", sans-serif;
}
html {
  scroll-behavior: smooth;
}
.home {
  margin: auto;
  width: 70%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1024px) {
  .home {
    width: 90%;
  }
}

@media only screen and (max-width: 512px) {
  .home {
    width: 100%;
  }
}
